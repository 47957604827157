import { observer } from "mobx-react-lite"
// import store from "../../RegistrationCoursePage.store"
import { Button, Space, Select } from "antd"
import Registration from "components/Registration/Registration"
import AuthForm from "modules/auth/AuthForm"
import RegistrationStore from "../../../RegistrationPage/RegistrationStore"
import { useEffect } from "react"

const { Option } = Select
export const Course = observer(({ params }) => {
	useEffect(() => {
		if (Number(params.system) === 5) {
			RegistrationStore.checkSystem()
		}
	}, [params])

	return RegistrationStore.authorized === true ? (
		<Space size="middle" direction="vertical" align="center">
			Вы уже авторизованы
			{Number(params.system) === 5 && (
				<Select
					style={{ width: "240px" }}
					size="large"
					value={RegistrationStore.installments}
					onChange={(event) => RegistrationStore.onChangeInstallments(event)}
					placeholder="Срок рассрочки"
					required
				>
					<Option value={String("installment_0_0_4_4,8")}>4 месяца</Option>
					<Option value={String("installment_0_0_6_6,8")}>6 месяцев</Option>
					<Option value={String("installment_0_0_10_10,5")}>10 месяцев</Option>
					<Option value={String("installment_0_0_12_12,2")}>12 месяцев</Option>
				</Select>
			)}
			<Button
				disabled={RegistrationStore.buttonDisabled}
				type="primary"
				size="large"
				onClick={(event) =>
					RegistrationStore.clickPayment(
						event,
						Number(params.id),
						Number(params.amount),
						Number(params.system),
					)
				}
				block
			>
				Перейти к оплате
			</Button>
		</Space>
	) : RegistrationStore.regTitleStatus ? (
		RegistrationStore.regStatus ? (
			<Registration
				courseID={Number(params.id)}
				amount={Number(params.amount)}
				systemID={Number(params.system)}
			/>
		) : (
			<AuthForm courseID={Number(params.id)} amount={Number(params.amount)} systemID={Number(params.system)} />
		)
	) : (
		<Space size="middle" direction="vertical">
			<Button type="primary" size="large" onClick={() => RegistrationStore.regButton()} block>
				Зарегистрироваться
			</Button>
			<Button size="large" onClick={() => RegistrationStore.authButton()} block>
				Авторизоваться
			</Button>
		</Space>
	)
})
