import { observer } from "mobx-react-lite"
import { Typography } from "antd"
import "./TitlePage.scss"

const { Title } = Typography
const TitlePage = (props) => {
	const title = props.title
	return (
		<div className="TitlePage">
			<Title level={2}>{title}</Title>
		</div>
	)
}

export default observer(TitlePage)
