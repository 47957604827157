import { action, makeAutoObservable } from "mobx"
import axios from "../../axios"

class SuccessStore {
	statusGet = ""
	constructor() {
		makeAutoObservable(this)
	}

	payment = []

	// TODO: Если пользователь не передает параметры, то что делаем

	getStatus = (params) => {
		let orderId = params.filter((item) => {
			return item.key === "orderId" ? item.value : undefined
		})

		this.getCheck(orderId)
	}

	getListStatus = (list) => {
		return list.map((item) => {
			return this.getCheck(item, true)
		})
	}

	getCheck = (pay, status = false) => {
		axios
			.get("/payments/check/", {
				params: {
					payment: status === false ? pay[0].value : pay.value,
				},
			})
			.then(
				action((success) => {
					this.statusGet = success.data
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}

	getByExt(id) {
		axios
			.get("/payments/ext/" + id)
			.then(
				action((success) => {
					this.payment = success.data
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}
}

export default new SuccessStore()
