import axios from "../../axios"
import { action, makeAutoObservable } from "mobx"
import { message } from "antd"
import locals from "../../store/locals"
import React from "react"

class UserStore {
	user = {}
	newCountry = {}
	statusEditUser = true
	addressInput = ""
	ymapsInput = ""
	address = ""
	countryList = ["Россия", "Казахстан", "Беларусь"]

	constructor() {
		makeAutoObservable(this)
		this.addressInput = React.createRef()
	}

	onYmapsLoad = (ymaps) => {
		this.ymaps = ymaps
		new this.ymaps.SuggestView(this.addressInput.current, {
			provider: {
				suggest: (request, options) => this.ymaps.suggest(request),
			},
		})
	}

	// addNewCountry(e) {
	// 	e.preventDefault()
	// 	if (!this.newCountry) return
	// 	this.countryList.push(this.newCountry)
	// 	this.newCountry = {}
	// }

	onChangeCountry(value) {
		this.newCountry = value
	}

	onChange(name, value) {
		this.user[name] = value
	}

	getUser() {
		axios
			.get(`/user/user/${locals.get("user").id}`)
			.then(
				action((success) => {
					if (!this.countryList.includes(success.data.country)) {
						this.countryList.push(success.data.country)
					}
					this.user = success.data
				}),
			)
			.catch((error) => {
				if (error.request.status === 400) {
					window.location.href = "/"
				}
			})
	}

	postUser(e) {
		e.preventDefault()
		let userPost = new FormData()

		this.address = this.addressInput.current.value.split(",")

		userPost.append("surname", this.user.surname)
		userPost.append("name", this.user.name)

		if (!this.user.phone) {
			this.statusEditUser = false
			return message.error("Введите номер телефона")
		}
		if (this.user.phone.length < 10) {
			this.statusEditUser = false
			return message.error("Введите номер телефона")
		}
		if (!this.user.email) {
			this.statusEditUser = false
			return message.error("Введите email")
		}

		userPost.append("phone", this.user.phone)
		userPost.append("email", this.user.email)

		if (this.address.length === 2) {
			userPost.append("country", this.address[0].trim())
			userPost.append("city", this.address[1].trim())
		} else if (this.address.length >= 3) {
			userPost.append("country", this.address[0].trim())
			userPost.append("city", this.address[2].trim())
		}

		if (!this.user.vk) {
			this.statusEditUser = false
			return message.error("Введите url на страницу VK")
		}

		if (!this.user.instagram) {
			this.statusEditUser = false
			return message.error("Введите url на страницу Instagram")
		}

		userPost.append("vk", this.user.vk)
		userPost.append("instagram", this.user.instagram)

		if (typeof this.user.password !== "undefined") {
			if (this.user.password === this.user.passwordConfirmation) {
				userPost.append("password", this.user.password)
			} else if (!this.user.password && !this.user.passwordConfirmation) {
				this.user.password = ""
				this.user.passwordConfirmation = ""
				return true
			} else if (this.user.password !== this.user.passwordConfirmation) {
				this.user.password = ""
				this.statusEditUser = false
				return message.error("Пароли не совпадают")
			}
		}

		axios
			.post(`/user/edit/${locals.get("user").id}`, userPost)
			.then(
				action((success) => {
					message.success("Обновлено!")
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}

	editUser = () => {
		this.statusEditUser = false
	}

	saveUser = () => {
		this.statusEditUser = true
	}
}

export default new UserStore()
