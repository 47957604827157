import { action, makeAutoObservable } from "mobx"
import axios from "../../axios"
import locals from "../../store/locals"
import config from "../../config.json"
// import { message } from "antd"

class WebinarStore {
	constructor() {
		makeAutoObservable(this)
	}

	webinar = []
	listWebinar = []
	limit = 9999
	regStatus = 1

	getWebinar(id) {
		axios
			.get("/webinars/webinar/" + id)
			.then(
				action((success) => {
					this.webinar = success.data
				}),
			)
			.catch((error) => {
				if (error.request.status === 400) {
					window.location.href = "/"
				}
			})
	}

	getAllWebinars() {
		axios
			.get("/webinars/webinars/?limit=" + this.limit + "&sort_start=1")
			.then(
				action((success) => {
					const [webinars, count] = success.data
					this.limit = count[0].count

					this.listWebinar = webinars.map((webinar) => {
						return {
							key: webinar.id,
							webinarStatus: false,
							...webinar,
						}
					})
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}

	makeWebinar = (WebinarID) => {
		let headers = {
			"User-Token": locals.get("user").token,
			Service: config.Service,
		}

		let facts = new FormData()
		facts.append("webinar_id", WebinarID)

		axios
			.post("/webinars/reg", facts, {
				headers: headers,
			})
			.then(
				action((success) => {
					this.listWebinar.map((webinar) => {
						return webinar.id === WebinarID ? (webinar.webinarStatus = true) : null
					})
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}

	checkWebinarReg(WebinarID) {
		axios
			.get("/webinars/check/" + WebinarID)
			.then(
				action((success) => {
					if (success.data === 1) {
						this.listWebinar.map((webinar) => {
							return webinar.id === WebinarID ? (webinar.webinarStatus = true) : null
						})
					} else if (success.data === 0) {
						this.listWebinar.map((webinar) => {
							return (webinar.webinarStatus = false)
						})
					}
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}

	checkSingleWebinarReg(WebinarID) {
		axios
			.get("/webinars/check/" + WebinarID)
			.then(
				action((success) => {
					this.regStatus = success.data
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}
}

export default new WebinarStore()
