import { Button, Col } from "antd"
import placeholder from "assets/images/placeholder.png"
import config from "config.json"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import dateFormat from "shared/hooks/dateFormat"
import "./WebinarItem.scss"

// Import Store
import WebinarStore from "modules/Webinars/Webinar.store"

const WebinarItem = ({ webinar }) => {
	useEffect(() => {
		WebinarStore.checkWebinarReg(webinar.id)
	})

	let now = Date.now() / 1000
	let showPlayButton = webinar.date_start - now < 0
	if (webinar.id === 10 || webinar.id === 11) showPlayButton = false

	return (
		<Col xs={24} md={12} lg={8} key={webinar.key} id={webinar.id} className="webinar">
			<div className="webinar-photo">
				{showPlayButton === true && webinar?.webinarStatus ? (
					<Link to={"/webinar/" + webinar.id}>
						<img
							src={webinar?.photo ? `${config.apiUrl}${webinar.photo}` : placeholder}
							alt={webinar?.name}
						/>
					</Link>
				) : (
					<img src={webinar?.photo ? `${config.apiUrl}${webinar.photo}` : placeholder} alt={webinar?.name} />
				)}
			</div>
			<div className="webinar-heading">{webinar?.name}</div>
			<div className="webinar-start">
				{webinar?.date_start
					? `Старт ${dateFormat(webinar?.date_start, "DD MMMM YYYY")} ${webinar?.time_start}`
					: "Скоро!"}
			</div>
			{webinar?.webinarStatus ? (
				<>
					<div className="webinar-text">Вы зарегистрированы</div>
					{showPlayButton === true && (
						<Link to={"/webinar/" + webinar.id}>
							<Button type="primary" className="webinar-btn">
								Перейти к просмотру
							</Button>
						</Link>
					)}
				</>
			) : (
				<Button
					type="primary"
					className="webinar-btn"
					onClick={() => {
						WebinarStore.makeWebinar(webinar.id)
					}}
				>
					Зарегистрироваться
				</Button>
			)}
		</Col>
	)
}

export default observer(WebinarItem)
