import axios from "axios"
import config from "./config.json"
import locals from "./store/locals"

let user = locals.get("user")
let userToken = ""
if (user && user.id > 0 && user.token) {
	userToken = user.token
}

let URL

process.env.NODE_ENV === "development" ? (URL = config.apiDevUrl) : (URL = config.apiUrl)
// URL = config.apiUrl

export default axios.create({
	baseURL: URL,
	headers: {
		"User-Token": userToken,
		Service: config.Service,
	},
})
