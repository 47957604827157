import { action, makeAutoObservable, runInAction } from "mobx"
import axios from "../../axios"

class LessonsStore {
	listLessons = {}
	next_lesson = []
	limit = 999
	listComments = []
	comments = []
	modalsState = []
	reply_to = 0
	isLoading = false

	constructor() {
		makeAutoObservable(this)
	}

	toggleCommentsModal(lesson_id) {
		let modal = this.modalsState.find((item) => item.lesson_id === lesson_id)
		if (modal) {
			let index = this.modalsState.indexOf(modal)
			this.modalsState[index].show = !this.modalsState[index].show
		} else {
			this.modalsState.push({
				lesson_id: lesson_id,
				show: true,
			})
		}
	}

	getLessons(coursesId) {
		this.isLoading = true
		axios
			.get(`/lessons/course/${coursesId}`, {
				limit: this.limit,
			})
			.then(
				action((success) => {
					const [items, next_lesson] = success.data[0]
					if (items) {
						this.next_lesson.push({
							date_lesson: next_lesson?.[0].next_lesson ?? items[items.length - 1]?.date_active,
							id_course: coursesId,
						})

						items.sort((a, b) => {
							if (a.date_active < b.date_active) return -1
							if (a.date_active > b.date_active) return 1
							return 0
						})

						this.listLessons[coursesId] = items.map((lesson) => {
							return { key: lesson.id, ...lesson }
						})

						items.map((lesson) => {
							return (
								lesson.comments === 1 &&
								axios.get("/comments/lesson/" + lesson.id).then(
									action((response) => {
										this.listComments.push({
											lesson_id: lesson.id,
											comments: response.data.reverse(),
										})
									}),
								)
							)
						})
					}
					this.isLoading = false
				}),
			)
			.catch((error) => {
				this.isLoading = false
				console.error(error)
			})
	}

	onChangeComment(lesson_id, text) {
		let comment = this.comments.find((item) => item.lesson_id === lesson_id)
		if (comment) {
			let index = this.comments.indexOf(comment)
			this.comments[index].text = text
		} else {
			this.comments.push({
				lesson_id: lesson_id,
				text: text,
			})
		}
	}

	sendComment(lesson_id, reply_to = 0) {
		let comment = this.comments.find((item) => item.lesson_id === lesson_id)
		if (!comment) return
		if (comment.text.length === 0) return

		let facts = new FormData()
		facts.append("text", comment.text)
		if (reply_to > 0) facts.append("reply_to", reply_to)
		axios.post("/comments/new/" + lesson_id, facts).then(
			action((success) => {
				axios.get("/comments/lesson/" + lesson_id).then(
					action((response) => {
						if (Number(success.data) === 1) {
							this.comments = []
						}
						this.listComments = []
						this.reply_to = 0
						this.listComments.push({
							lesson_id: lesson_id,
							comments: response.data.reverse(),
						})
						document.querySelector(".ant-modal-wrap").scrollTo(0, 0)
					}),
				)
			}),
		)
	}
	async updateLessonView({ lessonId, courseId }) {
		try {
			await axios.post(`/lessons/view/${lessonId}`, { courseId })
			const lessonIndex = this.listLessons.findIndex((lesson) => lesson.id === Number(lessonId))
			runInAction(() => {
				this.listLessons[lessonIndex].lastViewed = true
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export default new LessonsStore()
