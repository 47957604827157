import moment from "moment"
import "moment/locale/ru"
moment.locale("ru")

const dateFormat = (date, format = "DD.MM.YYYY") => {
	const formatDate = format
	return moment.unix(date).format(formatDate)
}

export default dateFormat
