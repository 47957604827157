import { action, makeAutoObservable } from "mobx"
import axios from "../../axios"
import locals from "../../store/locals"
import { message } from "antd"
import RegistrationStore from "../../pages/RegistrationPage/RegistrationStore"
import WebinarRegStore from "../../pages/WebinarReg/WebinarRegStore"

class AuthStore {
	newCountry = {}

	constructor() {
		makeAutoObservable(this)
	}

	user = {
		phone: "",
		password: "",
		code: "",
	}
	clearCode() {
		this.user.code = ""
	}

	newUser = {
		regEmail: "",
		regPhone: "",
		regName: "",
		regCountry: "",
		regCity: "",
		regPassword: "",
		regPasswordConfirmation: "",
	}

	inputForgotPass = ""

	hiddenForgotPass = true

	authByPassword = false

	waitForCode = false

	successForgotPass = false

	globalMinutes = 3
	codeTimeout = false
	timer = {
		minutes: "0" + this.globalMinutes,
		seconds: "00",
	}
	buttonDisabled = false

	statusResponse = false

	onChange(name, value) {
		this.user[name] = value
	}

	onChangeForgotPass = (value) => {
		this.inputForgotPass = value
	}

	onChangeReg(name, value) {
		this.newUser[name] = value
	}

	countryList = ["Россия", "Казахстан", "Беларусь"]

	addNewCountry(e) {
		e.preventDefault()
		if (!this.newCountry) return
		this.countryList.push(this.newCountry)
		this.newCountry = {}
	}

	onChangeCountry(value) {
		this.newCountry = value
	}

	LogIn(e, courseID, amount, systemID, webinarID) {
		e.preventDefault()
		this.buttonDisabled = true
		let facts = new FormData()
		if (/^(89)/g.test(this.user.phone)) {
			let arrayOfStrings = this.user.phone.split("")
			arrayOfStrings[0] = "7"
			facts.append("login", arrayOfStrings.join(""))
		} else {
			facts.append("login", this.user.phone)
		}
		facts.append("password", this.user.password)
		this.statusResponse = true
		axios
			.post("user/login", facts)
			.then(
				action((success) => {
					this.statusResponse = false
					if (success.data !== 0) {
						locals.set("user", success.data)
						if (webinarID && webinarID > 0) {
							WebinarRegStore.makeWebinar(webinarID)
							window.location.reload()
						} else {
							if (courseID > 0 && amount >= 0) {
								RegistrationStore.paymentChange(courseID, amount, systemID)
							} else if (courseID === 0 && amount === 0 && systemID === 1 && webinarID === 0) {
								document.location.href = "/payments"
							} else {
								document.location.href = "/payments"
							}
						}
					} else {
						message.error("Неправильный логин или пароль.")
						this.buttonDisabled = false
					}
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}

	setTimer() {
		this.codeTimeout = true
		this.timer = {
			minutes: "0" + this.globalMinutes,
			seconds: "00",
		}
	}

	LogInByCode(e, courseID, amount, systemID, webinarID) {
		e.preventDefault()
		this.waitForCode = true
		this.buttonDisabled = true
		let facts = new FormData()
		const isCheckCode = !!this.user.code
		const isRetryOpenModal = this.codeTimeout === true && this.user.code === ""
		if (isRetryOpenModal) {
			return false
		} else {
			this.codeTimeout = true
		}
		if (isCheckCode) {
			facts.append("code", this.user.code)
		} else {
			locals.set("codeSend", new Date())
		}

		if (/^(89)/g.test(this.user.phone)) {
			let arrayOfStrings = this.user.phone.split("")
			arrayOfStrings[0] = "7"
			facts.append("login", arrayOfStrings.join(""))
		} else {
			facts.append("login", this.user.phone)
		}

		axios
			.post("user/code", facts)
			.then(
				action((success) => {
					this.buttonDisabled = false
					// this.setTimer()
					this.codeTimeout = true
					if (success.data === 1) {
					} else if (success.data === 0) {
						message.error("Неправильный код.")
					} else if (success.data === 2) {
						locals.remove("codeSend")
						message.error("Произошла ошибка. Попробуйте позже.")
						this.codeTimeout = false
					} else if (success.data === 3) {
						locals.remove("codeSend")
						this.codeTimeout = false
						message.error("Пользователя с таким номером телефона не существует.")
					} else {
						message.success("Успешно")
						locals.remove("codeSend")
						locals.set("user", success.data)
						if (webinarID && webinarID > 0) {
							WebinarRegStore.makeWebinar(webinarID)
							window.location.reload()
						} else {
							if (courseID > 0 && amount >= 0) {
								RegistrationStore.paymentChange(courseID, amount, systemID)
							} else if (courseID === 0 && amount === 0 && systemID === 1 && webinarID === 0) {
								document.location.href = "/payments"
							} else {
								document.location.href = "/payments"
							}
						}
					}
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}

	checkRemainTime() {
		const oneMinute = 60000
		let codeSend = new Date(locals.get("codeSend")).getTime()
		let now = new Date().getTime()
		let diff = now - codeSend
		let diffReverse = oneMinute * this.globalMinutes - diff
		let minutes = Math.floor(diffReverse / oneMinute)
		let seconds = Math.floor(diffReverse / 1000 - minutes * 60)
		if (diff < this.globalMinutes * oneMinute) {
			this.codeTimeout = true
			this.timer = {
				minutes: ("0" + minutes).slice(-2),
				seconds: ("0" + seconds).slice(-2),
			}
			if ((minutes === 0 && seconds === 0) || diff > this.globalMinutes * oneMinute) {
				//locals.remove("codeSend");
				this.codeTimeout = false
			}
		} else {
			//locals.remove("codeSend");
			this.codeTimeout = false
		}
	}

	LogOut(e) {
		e.preventDefault()
		locals.remove("user")
		document.location.href = "/"
	}

	OpenForgotPass = (e) => {
		e.preventDefault()
		this.authByPassword = true
		this.hiddenForgotPass = false
	}

	CloseForgotPass = (e) => {
		e.preventDefault()
		this.authByPassword = true
		this.hiddenForgotPass = true
	}

	waitForResponse = false

	ForgotPass(e) {
		e.preventDefault()

		let user = new FormData()
		if (this.user.phone.length < 10) return message.error("Не указан номер телефона")
		if (/^(89)/g.test(this.user.phone)) {
			let arrayOfStrings = this.user.phone.split("")
			arrayOfStrings[0] = "7"
			user.append("login", arrayOfStrings.join(""))
		} else {
			user.append("login", this.user.phone)
		}
		this.waitForResponse = true
		axios
			.post("/user/recovery", user)
			.then((success) => {
				this.waitForResponse = false
				return 0
			})
			.catch((error) => {
				this.waitForResponse = false
				return 0
			})
		this.successForgotPass = true
		this.user.phone = "7"
	}
}

export default new AuthStore()
