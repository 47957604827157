import React from "react"
import "./PersonalUser.scss"
import { Button, Space, Input } from "antd"
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons"
import UserStore from "../../../modules/Personal/UserStore"
import { observer } from "mobx-react-lite"
import { YMaps, Map } from "@pbe/react-yandex-maps"

const mapState = {
	center: [55.76, 37.64],
	zoom: 10,
	controls: [],
}
const re = /^[A-Za-z0-9@.,:/_-]+$/
const PersonalUser = () => {
	return (
		<>
			<form
				className="PersonalUserForm"
				onSubmit={(e) => {
					UserStore.postUser(e)
				}}
			>
				{/* <Row align="middle" className="PersonalUserForm__header">
					<Col flex="none">
						<div className="toggleEdit">
							<Button
								type="link"
								onClick={() => UserStore.editUser()}
								className={
									UserStore.statusEditUser
										? "toggleEdit--active"
										: "toggleEdit--default"
								}
							>
								<EditOutlined
									style={{
										fontSize: "30px",
										marginRight: "10px",
										color: "rgba(0, 0, 0, 0.85)",
									}}
								/>
							</Button>
							<Button
								type="link"
								onClick={(e) => UserStore.saveUser()}
								htmlType="submit"
								className={
									!UserStore.statusEditUser
										? "toggleEdit--active"
										: "toggleEdit--default"
								}
							>
								<SaveOutlined
									style={{
										fontSize: "30px",
										marginRight: "10px",
										color: "rgba(0, 0, 0, 0.85)",
									}}
								/>
							</Button>
						</div>
					</Col>

					<Col flex="auto">
						<Title level={2} className="PersonalUserForm--title">
							Личные данные
						</Title>
					</Col>
				</Row> */}
				<Space direction="vertical" className="PersonalUserForm__body">
					<div className="PersonalUserForm__body-group">
						<label>E-mail:</label>
						<Input
							defaultValue={UserStore.user.email}
							value={UserStore.user.email}
							bordered={!UserStore.statusEditUser}
							onChange={(e) =>
								UserStore.onChange("email", e.target.value)
							}
							disabled={UserStore.statusEditUser}
							type="email"
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Телефон:</label>
						<Input
							defaultValue={UserStore.user.phone}
							value={UserStore.user.phone}
							bordered={!UserStore.statusEditUser}
							onChange={(e) =>
								UserStore.onChange("phone", e.target.value)
							}
							disabled={UserStore.statusEditUser}
							type="text"
							inputmode="tel"
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Имя:</label>
						<Input
							defaultValue={UserStore.user.name}
							value={UserStore.user.name}
							bordered={!UserStore.statusEditUser}
							onChange={(e) =>
								UserStore.onChange("name", e.target.value)
							}
							disabled={UserStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Фамилия:</label>
						<Input
							defaultValue={UserStore.user.surname}
							value={UserStore.user.surname}
							bordered={!UserStore.statusEditUser}
							onChange={(e) =>
								UserStore.onChange("surname", e.target.value)
							}
							disabled={UserStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Страна, город:</label>
						<input
							id="suggest"
							className="ant-input input-city"
							ref={UserStore.addressInput}
							placeholder={
								UserStore.user.country && UserStore.user.city
									? `${UserStore.user.country}, ${UserStore.user.city}`
									: "Введите город"
							}
							disabled={UserStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>VK:</label>
						<Input
							defaultValue={UserStore.user.vk}
							value={UserStore.user.vk}
							bordered={!UserStore.statusEditUser}
							onChange={(e) => {
								if (
									e.target.value === "" ||
									re.test(e.target.value)
								) {
									UserStore.onChange("vk", e.target.value)
								}
							}}
							disabled={UserStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Instagram:</label>
						<Input
							defaultValue={UserStore.user.instagram}
							value={UserStore.user.instagram}
							bordered={!UserStore.statusEditUser}
							onChange={(e) => {
								if (
									e.target.value === "" ||
									re.test(e.target.value)
								) {
									UserStore.onChange(
										"instagram",
										e.target.value,
									)
								}
							}}
							disabled={UserStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Пароль</label>
						<Input.Password
							iconRender={(visible) =>
								visible ? (
									<EyeTwoTone />
								) : (
									<EyeInvisibleOutlined />
								)
							}
							placeholder="******"
							value={UserStore.user.password}
							disabled={UserStore.statusEditUser}
							bordered={!UserStore.statusEditUser}
							onChange={(e) =>
								UserStore.onChange("password", e.target.value)
							}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Подтвердите пароль</label>
						<Input.Password
							placeholder="******"
							iconRender={(visible) =>
								visible ? (
									<EyeTwoTone />
								) : (
									<EyeInvisibleOutlined />
								)
							}
							value={UserStore.user.passwordConfirmation}
							disabled={UserStore.statusEditUser}
							bordered={!UserStore.statusEditUser}
							onChange={(e) =>
								UserStore.onChange(
									"passwordConfirmation",
									e.target.value,
								)
							}
						/>
					</div>

					<div className="PersonalUserForm__body-button">
						<Button
							type="primary"
							onClick={() => UserStore.editUser()}
							size="large"
							className={
								UserStore.statusEditUser
									? "toggleEdit--active"
									: "toggleEdit--default"
							}
						>
							Редактировать
						</Button>
						<Button
							type="primary"
							size="large"
							onClick={(e) => UserStore.saveUser()}
							htmlType="submit"
							className={
								!UserStore.statusEditUser
									? "toggleEdit--active"
									: "toggleEdit--default"
							}
						>
							Сохранить
						</Button>
					</div>
				</Space>
				<div style={{ display: "none" }}>
					<YMaps query={{ load: "package.full" }}>
						<Map
							state={mapState}
							onLoad={UserStore.onYmapsLoad}
							instanceRef={(map) => (UserStore.map = map)}
						/>
					</YMaps>
				</div>
			</form>
		</>
	)
}

export default observer(PersonalUser)
