import { makeAutoObservable } from "mobx"
import axios from "./axios"
import locals from "./store/locals"

class Middleware {
	constructor() {
		makeAutoObservable(this)
	}

	isLogged() {
		let userAuth = false
		let user = locals.get("user")
		if (user && user.id > 0 && user.token) {
			userAuth = true
		}

		return userAuth
	}

	checkToken() {
		axios
			.get("/user/token")
			.then((success) => {
				if (success.data !== 1) {
					locals.clearAll()
					document.location.href = "/"
				}
			})
			.catch((error) => {
				locals.clearAll()
				document.location.href = "/"
			})
	}
}

export default new Middleware()
