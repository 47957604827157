// Import Components
import { ArrowLeftOutlined } from "@ant-design/icons"

// Import Style
import "./HeaderBack.scss"

const HeaderBack = ({ title, store }) => {
	return (
		<button
			className="site-page-header HeaderBack"
			onClick={() => store.regToggleTitle()}
		>
			<div className="HeaderBack-icon">
				<ArrowLeftOutlined />
			</div>
			<div className="HeaderBack-title">{title}</div>
		</button>
	)
}

export default HeaderBack
