// Import Framework
import SEO from "@americanexpress/react-seo"

import { observer } from "mobx-react-lite"
// Import Components
import { useEffect } from "react"
import Header from "../../components/Header/Header"
import PaymentsList from "../../components/Payments/PaymentsList"
import TitlePage from "../../components/TitlePage/TitlePage"

// Import Style

// Import Store
import CoursesStore from "../../modules/Courses/CoursesStore"
// import PaymentsStore from "../../modules/Paymonts/PaymontsStore"

// Import Hooks

const PaymentsPage = () => {
	// const payment = PaymentsStore
	const course = CoursesStore
	useEffect(() => {
		// payment.getPaymentsCount()
		// course.getAllCourses()
		course.getMyAllCourses()
	}, [])

	const updatePayments = () => {
		CoursesStore.getAllCourses()
		// PaymentsStore.getAllPayments()
	}

	return (
		<>
			<SEO title="Личный кабинет - Modnaya Kontora" />
			<Header />
			<div className="HomePage">
				<div className="container">
					<TitlePage title="Ваши покупки" />
					{CoursesStore?.listMyCourses?.length > 0 ? (
						<PaymentsList
							// payments={PaymentsStore?.listPayments}
							courses={CoursesStore?.listMyCourses}
						/>
					) : (
						<button className="course-site-link" onClick={updatePayments}>
							Обновить данные
						</button>
					)}
				</div>
			</div>
		</>
	)
}

export default observer(PaymentsPage)
