import { store } from "./RegCoursePage.store"
import styles from "./RegCoursePage.module.scss"
import R from "react-roubles"
import PhoneInput from "react-phone-input-2"
import ru from "react-phone-input-2/lang/ru.json"
import { Input } from "./ui"
import { observer } from "mobx-react-lite"
import { Link, useLocation, useParams } from "react-router-dom"
import { Select } from "components/select/select"
import locals from "store/locals"
import { useState } from "react"

const RegCoursePage = () => {
	const { id, amount, system } = useParams()

	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)
	const returnedURL = queryParams.get("refererUrl")
	const handlerSend = async () => {
		if (store.status) {
			if (!store.user.form) {
				await store.sendUser()

				if (locals.get("user")) {
					await store.clickPayment(Number(id), Number(amount), Number(system))
					// setTimeout(() => {
					// 	// store.payment(id, amount, system)
					// 	store.clickPayment(Number(id), Number(amount), Number(system))
					// }, 1000)
				}
			}
		}
	}

	const [isLoading, setIsLoading] = useState(false)

	const handlerSendUser = async (event) => {
		setIsLoading(true)
		event.preventDefault()
		await store.validateCourseId(id)
		await handlerSend()
		setIsLoading(false)
		// setTimeout(() => {
		// 	handlerSend()
		// }, 1000)
	}

	return (
		<div className={styles.container}>
			<form className={styles.form} onSubmit={handlerSendUser}>
				<div className={styles.formInputs}>
					<Input
						placeholder="Ваше имя"
						onChange={(event) => store.onChange("name", event.target.value)}
						value={store.user.name}
						required={true}
					/>

					<Input
						placeholder="Ваша фамилия"
						onChange={(event) => store.onChange("surname", event.target.value)}
						value={store.user.surname}
						required={true}
					/>

					<Input
						type="email"
						placeholder="Ваш e-mail"
						onChange={(event) => store.onChange("email", event.target.value)}
						value={store.user.email}
						required={true}
					/>

					<PhoneInput
						containerClass={styles.phoneContainer}
						inputClass={styles.phoneInput}
						buttonClass={styles.phoneButton}
						localization={ru}
						preferredCountries={["ru"]}
						inputProps={{
							required: true,
						}}
						country={"ru"}
						value={store.user.phone}
						onChange={(phone) => store.onChange("phone", phone)}
					/>

					<Input
						placeholder="Ваш instagram"
						onChange={(event) => store.onChange("instagram", event.target.value)}
						value={store.user.instagram}
						required={true}
					/>

					<Input
						placeholder="Ваш ВКонтакте"
						onChange={(event) => store.onChange("vk", event.target.value)}
						value={store.user.vk}
						required={true}
					/>

					{Number(system) === 5 && (
						<div className={styles.formInstallments}>
							<span className={styles.formInstallmentsTitle}>Период рассрочки</span>
							<Select
								value={store.user.installments}
								onChange={store.onChangeSelect}
								placeholder={"Выберите период"}
								options={[
									{
										id: "installment_0_0_4_4,9",
										name: "4 месяца",
									},
									{
										id: "installment_0_0_6_6,8",
										name: "6 месяцев",
									},
									{
										id: "installment_0_0_10_10,5",
										name: "10 месяцев",
									},
									{
										id: "installment_0_0_12_12,2",
										name: "12 месяцев",
									},
								]}
							/>
						</div>
					)}

					{amount > 0 && (
						<div className={styles.formPromocode}>
							<Input
								placeholder="Промокод"
								onChange={(event) => store.onChange("promocode", event.target.value)}
								value={store.user.promocode}
							/>
							<button
								className={styles.formBtnPromo}
								type="button"
								onClick={(event) => store.checkPromocode(event, id, amount)}
							>
								Применить
							</button>
						</div>
					)}

					<div className={styles.formCheck}>
						<input
							type="checkbox"
							name="check"
							id="check"
							onChange={() => store.toggleChecked()}
							checked={store.user.checked}
							required={true}
						/>
						<label htmlFor="check">
							Отправляя данную форму я соглашаюсь с политикой конфиденциальности сайта
						</label>
					</div>
				</div>

				{amount > 0 && (
					<div className={styles.formRub}>
						<R>{store.user.discountedPrice !== null ? store.user.discountedPrice : amount}</R>
					</div>
				)}

				<button
					className={`${styles.formBtn} ${isLoading ? styles.formBtnLoading : ""}`}
					htmlFor="submit"
					disabled={store.user.form}
				>
					{amount > 0 ? "Оплатить курс" : "Зарегистрироваться"}
				</button>
				{returnedURL && (
					<a className={styles.formBtn} type="button" href={returnedURL}>
						Вернуться назад
					</a>
				)}
			</form>
		</div>
	)
}

export default observer(RegCoursePage)
