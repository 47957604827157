import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Button, Modal } from "antd"
import Registration from "../../components/Registration/Registration"

const RegistrationForm = observer(() => {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	return (
		<>
			<Button type="link" onClick={showModal}>
				Зарегистрироваться
			</Button>

			<Modal title="Регистрация" open={isModalVisible} footer="" width={328} onCancel={handleCancel}>
				<Registration />
			</Modal>
		</>
	)
})

export default RegistrationForm
