import { message } from "antd"
import { makeAutoObservable } from "mobx"
import axios from "../../axios"
import locals from "../../store/locals"
import React from "react"
import config from "../../config.json"
import WebinarRegStore from "../WebinarReg/WebinarRegStore"
import { v4 as uuidv4 } from "uuid"

class RegistrationStore {
	addressInput = ""
	ymapsInput = ""
	user = {
		email: "",
		phone: "",
		name: "",
		surname: "",
		vk: "",
		instagram: "",
		country: "",
		city: "",
	}
	person = null
	regTitleStatus = false
	regStatus = true
	invalidCourseID = false
	course = []
	alreadyAuthorized = false
	buttonDisabled = false
	authorized = false
	paymentCoursesLength = 0
	paymentCoursesList = []
	installments = null

	constructor() {
		makeAutoObservable(this)
		this.userInput = React.createRef()
	}

	clickPayment = (event, id, amount, system) => {
		event.preventDefault()

		this.buttonDisabled = true
		this.paymentChange(id, amount, system)
	}

	paymentChange = (id, amount, system) => {
		if (Number(id) === 74) {
			this.paymentCoursesLength = 4
			this.paymentCourses(Number(73), 0, system)
			this.paymentCourses(Number(72), 0, system)
			this.paymentCourses(Number(71), 0, system)
			this.paymentCourses(Number(70), 0, system)
			this.paymentCourses(Number(74), amount, system)
		} else {
			this.makePayment(id, amount, system)
		}
	}

	checkSystem = () => {
		if (this.installments === null) {
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
		}
	}

	checkPage = (params, user) => {
		if (Number(params.id) > 0 && Number(params.amount) >= 0 && Number(params.system) > 0) {
			this.checkCourseId(params.system)
			this.checkUser(user)
			this.validateCourseId(Number(params.id))
		} else {
			this.invalidCourseID = true
		}
	}

	checkCourseId = (system) => {
		if (Number(system) !== 1 && Number(system) !== 2 && Number(system) !== 3 && Number(system) !== 5) {
			this.invalidCourseID = true
		}
	}

	checkUser = (user) => {
		this.person = user
		if (user && Number(user.id) > 0 && user.token) {
			this.authorized = true
		}
	}

	onChangeReg(name, value) {
		this.user[name] = value
	}

	onChangeInstallments = (event) => {
		this.installments = event
		if (this.installments === null) {
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
		}
	}

	regButton = () => {
		this.regTitleStatus = true
		this.regStatus = true
	}

	authButton = () => {
		this.regTitleStatus = true
		this.regStatus = false
	}

	regToggleTitle() {
		this.regTitleStatus = false
	}

	onYmapsLoad = (ymaps) => {
		this.ymaps = ymaps
		new this.ymaps.SuggestView(this.userInput.current, {
			provider: {
				suggest: (request, options) => this.ymaps.suggest(request),
			},
		})
	}

	SignUp(e, courseID, amount, systemID, webinarID) {
		e.preventDefault()
		this.buttonDisabled = true
		let reg = new FormData()

		if (this.user.email !== "") {
			reg.append("email", this.user.email)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите email")
		}

		if (this.user.phone !== "") {
			reg.append("phone", this.user.phone)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите номер")
		}

		if (this.user.name !== "") {
			reg.append("name", this.user.name)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите имя")
		}

		if (this.user.surname !== "") {
			reg.append("surname", this.user.surname)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите фамилию")
		}

		if (this.user.country !== "") {
			reg.append("country", this.user.country)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите страну")
		}

		if (this.user.city !== "") {
			reg.append("city", this.user.city)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите город")
		}

		if (this.user.vk !== "") {
			reg.append("vk", this.user.vk)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите vk")
		}

		if (this.user.instagram !== "") {
			reg.append("instagram", this.user.instagram)
			this.buttonDisabled = true
		} else {
			this.buttonDisabled = false
			return message.error("Введите instagram")
		}

		axios
			.post("user/new", reg)
			.then((success) => {
				if (success.data !== 0) {
					message.success("Успешно")
					locals.set("user", success.data)
					if (webinarID > 0) {
						WebinarRegStore.makeWebinar(webinarID)
						window.location.reload()
					} else {
						if (courseID > 0 && amount >= 0) {
							this.paymentChange(courseID, amount, systemID)
						} else if (courseID === 0 && amount === 0 && systemID === 1 && webinarID === 0) {
							document.location.href = "/"
						} else {
							document.location.href = "/"
						}
					}
				} else {
					message.error("Пользователь с таким телефоном или e-mail уже существует.")
					this.buttonDisabled = false
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	validateCourse(id) {
		axios
			.get("courses/course/" + id)
			.then((success) => {
				this.course = success.data
			})
			.catch((error) => {
				this.invalidCourseID = true
			})
	}

	validateCourseId = (id) => {
		// TODO: Сделать другой запрос, чтобы тот отдавал {"success": true} и {"success":false, "message": "Курс не существует"}
		axios
			.get("courses/course/" + id)
			.then((success) => {
				this.course = success.data
			})
			.catch((error) => {
				this.invalidCourseID = true
				console.error(error)
			})
	}

	makePayment = (courseID, amount, systemID) => {
		if (this.invalidCourseID === false) {
			let headers = {
				"User-Token": locals.get("user").token,
				Service: config.Service,
			}
			let facts = new FormData()

			if (this?.course?.id) {
				facts.append("courseID", this?.course?.id)
			} else {
				facts.append("courseID", courseID)
			}

			facts.append("amount", amount)

			facts.append("system", systemID)

			let title = "Обучение " + this?.course?.title

			if (Number(systemID) !== 1) title += " (рассрочка)"

			if (systemID === 5) {
				facts.append("promocode", this.installments)
			}

			facts.append("comment", title)

			axios
				.post("payments/new", facts, {
					headers: headers,
				})
				.then((success) => {
					if (amount === 0 && success?.data?.ext_id) {
						return (window.location.href = `/success?orderId=${success?.data?.ext_id}`)
					}

					if (systemID === 1 || systemID === 2 || systemID === 3 || systemID === 5) {
						return (window.location.href = success.data.url)
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}

	paymentCourses = (courseID, amount, systemID) => {
		if (this.invalidCourseID === false) {
			let headers = {
				"User-Token": locals.get("user").token,
				Service: config.Service,
			}

			let facts = new FormData()

			if (this?.course?.id) {
				facts.append("courseID", this?.course?.id)
			} else {
				facts.append("courseID", courseID)
			}

			facts.append("amount", amount)
			facts.append("system", systemID)

			let title = "Обучение " + this?.course?.title

			if (Number(systemID) !== 1) title += " (рассрочка)"

			facts.append("comment", title)

			axios
				.post("payments/new", facts, {
					headers: headers,
				})
				.then((success) => {
					if (!success.data.url) {
						this.paymentCoursesList.push({ id: uuidv4(), ext: success.data.ext_id })
					}

					if (systemID === 1 || systemID === 3) {
						if (this.paymentCoursesLength === this.paymentCoursesList.length) {
							locals.set("payments", this.paymentCoursesList)

							if (success.data.url) {
								return (window.location.href = success.data.url)
							}
						}
					}

					if (systemID === 2 || systemID === 5) {
						return (window.location.href = success.data.url)
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}
}

export default new RegistrationStore()
