import { action, makeAutoObservable, runInAction } from "mobx"
import axios from "../../axios"

class CoursesStore {
	listCourses = []
	listCategories = []
	course = {}
	listMyCourses = []
	isLoading = false

	constructor() {
		makeAutoObservable(this)
	}

	async getMyAllCourses() {
		try {
			const { data } = await axios.get("/courses/my")
			runInAction(() => {
				this.listMyCourses = data
			})
		} catch (error) {
			console.error(error)
		}
	}

	getAllCourses() {
		this.isLoading = true
		axios
			.get("/courses/courses", {
				params: { limit: 999, offset: 0 },
			})
			.then(
				action((success) => {
					const [courses] = success.data
					this.listCourses = courses.map((course) => {
						if (course.in_numbers !== null) {
							if (!(course.in_numbers instanceof Array)) {
								course.in_numbers = [course.in_numbers]
							}
						} else {
							course.in_numbers = []
						}
						return { key: course.id, ...course }
					})
					this.isLoading = false
				}),
			)
			.catch((error) => {
				this.isLoading = false
				console.error(error)
			})
	}

	maxPrice = () => {
		const priceArray = []
		if (this.course.prices) {
			this.course.prices.map((price, index) => {
				return priceArray.push(price.amount)
			})
			return Math.max.apply(null, priceArray)
		}
	}

	getCourse(id) {
		axios
			.get(`/courses/course/${id}`)
			.then(
				action((success) => {
					this.course = success.data

					if (this.course.prices !== null) {
						this.course.prices = JSON.parse(success.data.prices)
						if (!(this.course.prices instanceof Array)) {
							this.course.prices = [this.course.prices]
						}
					} else {
						this.course.prices = []
					}

					if (this.course.in_numbers !== null) {
						this.course.in_numbers = JSON.parse(success.data.in_numbers)
						if (!(this.course.in_numbers instanceof Array)) {
							this.course.in_numbers = [this.course.in_numbers]
						}
					} else {
						this.course.in_numbers = []
					}

					if (this.course.team !== null) {
						this.course.team = JSON.parse(success.data.team)
						if (!(this.course.team instanceof Array)) {
							this.course.team = [this.course.team]
						}
					} else {
						this.course.team = []
					}
				}),
			)
			.catch((error) => {
				if (error.request.status === 400) {
					window.location.href = "/"
				}
			})
	}

	getAllCategories() {
		axios
			.get("/categories/categories/")
			.then(
				action((success) => {
					const categories = success.data[0]
					this.listCategories = categories.map((category) => {
						return { key: category.id, ...category }
					})
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}
}

export default new CoursesStore()
