import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import successImg from "../../assets/images/success.png"
import expiredImg from "../../assets/images/expired.png"
import SuccessStore from "../../modules/SuccessStore/SuccessStore"
import "./SuccessPage.scss"
import SEO from "@americanexpress/react-seo"
import ym from "react-yandex-metrika"
import locals from "../../store/locals"

const SuccessPage = () => {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const urlParams = Array.from(queryParams)
	const store = SuccessStore

	let newFormatParams
	newFormatParams = urlParams.map((item, index) => {
		return (newFormatParams = {
			key: item[0],
			value: item[1],
		})
	})

	let payList
	if (locals.get("payments")) {
		payList = locals.get("payments").map((pay) => {
			return { key: "orderId", value: pay.ext }
		})
	}

	useEffect(() => {
		if (payList?.length > 0) {
			for (let i = 0; i < payList.length; i++) {
				SuccessStore.getListStatus(payList)
			}
		}
	}, [payList])

	useEffect(() => {
		SuccessStore.getStatus(newFormatParams)
		SuccessStore.getByExt(newFormatParams[0].value)
	}, [])

	if (store.statusGet === 1) {
		let ymFired = locals.get("payment_" + store.payment.id)
		if (ymFired !== 1) {
			if (store.payment.comment !== "") {
				ym("reachGoal", "successPayment")
				locals.set("payment_" + store.payment.id, 1)
				window.dataLayer.push({
					ecommerce: {
						currencyCode: "RUR",
						purchase: {
							actionField: {
								id: store.payment.id,
								goal_id: "251172053",
							},
							products: [
								{
									name: store.payment.comment,
									price: store.payment?.amount ?? 0,
									quantity: 1,
								},
							],
						},
					},
				})
			}
		}
	}

	return (
		<>
			{store.statusGet === 1 && (
				<>
					<SEO title="Спасибо за покупку!" />
					<div className="SuccessPage">
						<div className="SuccessPage__body">
							<div className="SuccessPage__img">
								<img src={successImg} alt="" />
							</div>
							<div className="SuccessPage__content">
								<h1 className="SuccessPage__content--title">Спасибо за покупку!</h1>
								<p className="SuccessPage__content--top">
									Вам был открыт доступ в личный кабинет пользователя для просмотра обучающих
									материалов. Страница входа:{" "}
									<a href="https://student.modnayakontora.ru">https://student.modnayakontora.ru</a>
								</p>
								<p className="SuccessPage__content--middle">
									Для авторизации укажите свой номер телефона и введите последние 4 цифры номера
									телефона, с которого поступит звонок. Если ваш оператор Мегафон, Йота, Газпром,
									введите 4 цифры, которые продиктует голосовой помощник.
								</p>
								<div className="SuccessPage__content--down">
									Если у вас возникли вопросы или сложности со входом - звоните нам по номеру{" "}
									<a href="tel:+88002013474">8 (800) 201-34-74</a> или пишите сообщение в{" "}
									<a href="whatsapp://send?phone=79827648944">WhatsApp</a>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{store.statusGet === 0 && (
				<>
					<SEO title="Ваша оплата находится в обработке." />
					<div className="SuccessPage">
						<div className="SuccessPage__body">
							<div className="SuccessPage__img">
								<img src={expiredImg} alt="" />
							</div>
							<div className="SuccessPage__content">
								<h1 className="SuccessPage__content--title">Ваша оплата находится в обработке.</h1>
								<p className="SuccessPage__content--top">Обновите страницу через несколько минут.</p>
							</div>
						</div>
					</div>
				</>
			)}

			{store.statusGet !== 0 && store.statusGet !== 1 && (
				<>
					<SEO title="Ошибка." />
					<div className="SuccessPage">
						<div className="SuccessPage__body">
							<h3>Загрузка...</h3>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default observer(SuccessPage)
