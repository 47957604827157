// import { message } from "antd"
import { makeAutoObservable } from "mobx"
import axios from "../../axios"
import locals from "../../store/locals"
import React from "react"
import config from "../../config.json"

class WebinarRegStore {
	addressInput = ""
	ymapsInput = ""
	address = ""
	user = {
		email: "",
		phone: "",
		name: "",
		vk: "",
		instagram: "",
	}
	regTitleStatus = false
	regStatus = true
	invalidWebinarID = false
	webinar = null
	successRegistration = false
	alreadyAuthorised = false
	buttonDisabled = false
	webinarName = null

	constructor() {
		makeAutoObservable(this)
		this.addressInput = React.createRef()
	}

	onChangeReg(name, value) {
		this.user[name] = value
	}

	regButton = () => {
		this.regTitleStatus = true
		this.regStatus = true
	}

	authButton = () => {
		this.regTitleStatus = true
		this.regStatus = false
	}

	regToggleTitle() {
		this.regTitleStatus = false
	}

	onYmapsLoad = (ymaps) => {
		this.ymaps = ymaps
		new this.ymaps.SuggestView(this.addressInput.current, {
			provider: {
				suggest: (request, options) => this.ymaps.suggest(request),
			},
		})
	}

	validateWebinar(id) {
		axios
			.get("/webinars/check/" + id)
			.then((success) => {
				this.webinar = success.data
			})
			.catch((error) => {
				this.invalidWebinarID = true
			})
	}

	getWebinar(WebinarID) {
		axios.get("/webinars/webinar/" + WebinarID).then((success) => {
			this.webinarName = success.data.name
		})
	}

	makeWebinar(WebinarID) {
		if (this.invalidWebinarID === false) {
			let headers = {
				"User-Token": locals.get("user").token,
				Service: config.Service,
			}
			let facts = new FormData()
			facts.append("webinar_id", WebinarID)

			axios
				.post("/webinars/reg", facts, {
					headers: headers,
				})
				.then((success) => {
					this.successRegistration = true
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}

	checkWebinarReg(WebinarID) {
		axios.get("/webinars/check/" + WebinarID).then((success) => {
			if (success.data === 1) {
				this.successRegistration = true
			}
		})
	}
}

export default new WebinarRegStore()
