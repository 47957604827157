// Import Components
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

// Import Style
import "./ButtonBack.scss"

const ButtonBack = ({ title, link }) => {
	const navigate = useNavigate()
	return (
		<button
			className="site-page-header ButtonBack"
			onClick={() => navigate(link)}
		>
			<div className="ButtonBack-icon">
				<ArrowLeftOutlined />
			</div>
			<div className="ButtonBack-title">{title}</div>
		</button>
	)
}

export default ButtonBack
