// Import Lib
import SEO from "@americanexpress/react-seo"
import { Anchor, Button, Col, Modal, Row, Typography } from "antd"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import CommentsList from "../../../components/Comments/CommentsList"
import Gallery from "../../../components/Gallery/Gallery"
import LessonText from "../../../components/LessonText/LessonText"
// import { DescriptionText } from "../../../components/DescriptionText/DescriptionText"

// Import Components
import Header from "../../../components/Header/Header"
import config from "../../../config.json"

// Import Style
import "../CoursesDetailPage.scss"

// Import Store
import { toJS } from "mobx"
import CoursesStore from "../../../modules/Courses/CoursesStore"
import LessonsStore from "../../../modules/Courses/LessonsStore"
import TeamStore from "../../../modules/Courses/TeamStore"
import locals from "../../../store/locals"

const { Title } = Typography
const NewPage = () => {
	const params = useParams()

	useLayoutEffect(() => {
		CoursesStore.getCourse(params.id)
		LessonsStore.getLessons(params.id)
		TeamStore.getCurator(params.id, locals.get("user").id)
	}, [params.id])
	const { guide_file } = CoursesStore?.course
	const listLessons = LessonsStore.listLessons[params.id] ?? []

	// Логика фиксации просмотренных уроков
	const [viewCheck, setViewCheck] = useState({
		isFirstLessonLoaded: false,
	})

	const handleVisible = () => {
		let timerId = {}
		return (events) => {
			for (const event of events) {
				const lessonId = event.target.dataset.lessonId
				if (event.isIntersecting) {
					timerId[lessonId] = setTimeout(() => {
						LessonsStore.updateLessonView({ courseId: params.id, lessonId })
					}, 30000)
				} else {
					clearTimeout(timerId[lessonId])
				}
			}
		}
	}

	useEffect(() => {
		if (viewCheck.isFirstLessonLoaded) return
		if (!listLessons.length) return

		let options = {
			root: document.querySelector("#scrollArea"),
			rootMargin: "50px 0px 50px 0px",
			threshold: 0,
		}

		let observer = new IntersectionObserver(handleVisible(), options)
		const triggers = document.querySelectorAll("[data-lesson-id]")

		for (const trigger of triggers) {
			observer.observe(trigger)
		}
		if (triggers.length) {
			setViewCheck((prev) => ({ ...prev, isFirstLessonLoaded: true }))
		}
	}, [listLessons])
	// ======================================================

	if (LessonsStore.isLoading || CoursesStore.isLoading) return <></>

	const course = CoursesStore.course
	const curator = TeamStore.curator
	if (!course) return <></>
	const timer = () => {
		if (LessonsStore?.next_lesson[0]?.date_lesson > 0) {
			const currentDate = new Date().getTime()
			const dateStart = Number(LessonsStore?.next_lesson[0].date_lesson) * 1000
			const newDate = moment(dateStart).from(currentDate).split(" ", 3)

			if (dateStart < currentDate) {
				return <span></span>
			} else {
				let word = "Следующая лекция"
				if (course.id === 21) word = "Следующий урок"
				return (
					<>
						<span>{word} начнется </span>
						<span className="CreateCourse__timer-item--one">{newDate[0]}</span>
						<span className="CreateCourse__timer-item--two">{newDate[2] ? newDate[1] : 1}</span>
						<span className="CreateCourse__timer-item--three">{newDate[2] ?? newDate[1]}</span>
					</>
				)
			}
		}
	}

	const checkTeam = LessonsStore.next_lesson.find((item) => {
		return item.id_course === params.id
	})

	let targetOffset = 50
	if (window.innerWidth < 768) targetOffset = 300

	let userCanReply = false
	let team = course?.teams
	let teamIDs = []
	if (team) {
		if (team.find((item) => item.user_id === locals.get("user").id)) {
			userCanReply = true
		}
		teamIDs = course.teams.map((item) => item.user_id)
	}

	return (
		<>
			<SEO title={course.title} />
			<Header />
			<div className="CreateCourse" id={course.id}>
				{/* Заголовок */}
				<section className="CreateCourse__title">
					<div className="container">
						<Title>{course.title}</Title>
					</div>
				</section>

				{guide_file && (
					<Row>
						<Col xs={24}>
							<div className="CreateCourse__guide-box">
								{/* <Link
									href={config.apiUrl + guide_file.path_name}
									target="_blank"
									className="CreateCourse__guide-link"
								> */}
								<div className="CreateCourse__lessons-item--button CreateCourse__guide-button-wrapper">
									<Button size="large" type="primary" href={config.apiUrl + guide_file.path_name}>
										Скачать гайд
									</Button>
								</div>
								{/* </Link> */}
							</div>
						</Col>
					</Row>
				)}

				<Row>
					{listLessons.length > 0 && (
						<Col xs={24} lg={8} xl={6} className="CreateCourse__body-left">
							<Anchor
								targetOffset={targetOffset}
								className={`CreateCourse__body-anchor
									${Boolean(checkTeam) === true ? "CreateCourse__body-anchor-true" : "CreateCourse__body-anchor-false"}
								`}
								offsetTop={0}
								onClick={(e) =>
									document
										.getElementsByClassName("CreateCourse__body-anchor")[0]
										.classList.remove("active")
								}
							>
								<div
									className="ant-anchor-link bold"
									onClick={(e) =>
										document
											.getElementsByClassName("CreateCourse__body-anchor")[0]
											.classList.toggle("active")
									}
								>
									Список лекций
								</div>
								{listLessons.map((lesson, index) => {
									return (
										<Anchor.Link
											key={index}
											id={`lesson_item-${lesson.id}`}
											href={`#lesson_${lesson.id}`}
											title={lesson.title}
										/>
									)
								})}
							</Anchor>
						</Col>
					)}
					{listLessons.length !== 0 && (
						<Col xs={24} lg={listLessons.length ? 16 : 24} xl={listLessons.length ? 18 : 24}>
							{curator && (
								<section className="CreateCourse__team">
									<div className="container">
										<Row gutter={[60, 60]} align="middle" className="CreateCourse__team-body">
											<Col xs={24} sm={9} lg={6}>
												<div className="CreateCourse__team-img">
													<img
														src={`${config.apiUrl}${curator?.photo}`}
														alt={curator?.name}
													/>
												</div>
											</Col>
											<Col xs={24} sm={15} lg={12} className="CreateCourse__team-body">
												<strong className="CreateCourse__team-title">{curator?.name}</strong>
												<p className="CreateCourse__team-post">{curator?.post}</p>
												<div className="CreateCourse__team-hr"></div>
												<div
													className="CreateCourse__team-description"
													dangerouslySetInnerHTML={{
														__html: curator?.description,
													}}
												></div>
												{curator?.contact && (
													<div className="CreateCourse__lessons-item--button">
														<Button
															size="large"
															type="primary"
															href={`${curator?.contact}`}
														>
															Связаться с куратором
														</Button>
													</div>
												)}
											</Col>
										</Row>
									</div>
								</section>
							)}

							{listLessons.length > 0 && (
								<section className="CreateCourse__body">
									<div className="CreateCourse__lessons">
										<div className="container">
											{listLessons.map((lesson, index) => {
												return (
													<div
														key={index}
														id={`lesson_${lesson.id}`}
														className="CreateCourse__lessons-item"
														{...(!lesson.lastViewed && { "data-lesson-id": lesson.id })}
													>
														<Title
															level={2}
															id="lesson"
															className="CreateCourse__lessons-item--title"
														>
															{lesson.title}
														</Title>
														{lesson.format === 1 && (
															<>
																<div className="CreateCourse__lessons-item-iframe">
																	<div
																		dangerouslySetInnerHTML={{
																			__html: `<iframe
																				title="${lesson.title}"
																				src="https://player.vimeo.com/video/${lesson.video}"
																				allow="autoplay; fullscreen"
																			/>`,
																		}}
																	/>
																</div>
																{/* <DescriptionText
																	description={lesson?.description}
																	text={lesson?.text}
																/> */}
																{lesson?.text && <LessonText text={lesson.text} />}
																<CommentsList lesson={lesson} short={true} />
															</>
														)}
														{lesson.format === 2 && (
															<Row gutter={42}>
																<Col xs={24} lg={12} xl={9}>
																	<div className="CreateCourse__lessons-item-iframe">
																		<div
																			dangerouslySetInnerHTML={{
																				__html: `<iframe
																					title="${lesson.title}"
																					class="vertical"
																					src="https://player.vimeo.com/video/${lesson.video}"
																					allow="autoplay; fullscreen"
																				/>`,
																			}}
																		/>
																	</div>
																</Col>
																<Col xs={24} lg={12} xl={15}>
																	{/* <DescriptionText
																		description={lesson?.description}
																		text={lesson?.text}
																	/> */}
																	{lesson?.text && <LessonText text={lesson.text} />}
																	<CommentsList lesson={lesson} short={true} />
																</Col>
															</Row>
														)}
														{lesson.format === 3 && (
															<Row gutter={42}>
																<Col xs={24} lg={12} xl={12}>
																	<Gallery items={lesson.images} />
																</Col>
																<Col xs={24} lg={12} xl={12}>
																	{/* <DescriptionText
																		description={lesson?.description}
																		text={lesson?.text}
																	/> */}
																	{lesson?.text && <LessonText text={lesson.text} />}
																	<CommentsList lesson={lesson} short={true} />
																</Col>
															</Row>
														)}
														{lesson.format === 4 && (
															<>
																<div className="CreateCourse__lessons-item-iframe">
																	<div
																		dangerouslySetInnerHTML={{
																			__html: `<iframe
																				src="https://www.youtube.com/embed/${lesson.video}?enablejsapi=1&origin=https://student.modnayakontora.ru&autoplay=0&cc_load_policy=0&cc_lang_pref=ru&iv_load_policy=3&loop=0&modestbranding=1&rel=0&fs=0&playsinline=1&autohide=2&theme=dark&color=red&controls=1&"
																				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
																				allowfullscreen=""
																			/>`,
																		}}
																	/>
																</div>
																{/* <DescriptionText
																	description={lesson?.description}
																	text={lesson?.text}
																/> */}
																{lesson?.text && <LessonText text={lesson.text} />}
																<CommentsList lesson={lesson} short={true} />
															</>
														)}
														{lesson.format === 5 && (
															<Row gutter={42}>
																<Col xs={24}>
																	{/* <DescriptionText
																		description={lesson?.description}
																		text={lesson?.text}
																	/> */}
																	{lesson?.text && <LessonText text={lesson.text} />}
																	<CommentsList lesson={lesson} short={true} />
																</Col>
															</Row>
														)}
														{lesson?.comments === 1 && teamIDs.length >= 0 && (
															<Modal
																footer={null}
																open={
																	LessonsStore.modalsState.find(
																		(item) => item.lesson_id === lesson.id,
																	)?.show
																}
																onCancel={(_) =>
																	LessonsStore.toggleCommentsModal(lesson.id)
																}
																width={420}
																style={{
																	top: 10,
																}}
															>
																<CommentsList
																	lesson={lesson}
																	canreply={userCanReply}
																	team={teamIDs}
																/>
															</Modal>
														)}
														{curator?.contact && (
															<div className="CreateCourse__lessons-item--button">
																<Button
																	size="large"
																	type="primary"
																	href={`${curator?.contact}`}
																>
																	Связаться с куратором
																</Button>
															</div>
														)}
													</div>
												)
											})}
										</div>
									</div>
								</section>
							)}
						</Col>
					)}
					<Col xs={24}>
						<section className="CreateCourse__timer">{timer()}</section>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(NewPage)
