import axios from "../../axios"
import { action, makeAutoObservable } from "mobx"

class TeamStore {
	listTeam = []
	curator = null

	constructor() {
		makeAutoObservable(this)
	}

	getCurator = (courseId, userId) => {
		axios
			.get(`/courses/curator/?course=${courseId}&user=${userId}`)
			.then(
				action((success) => {
					this.curator = success.data
				}),
			)
			.catch((error) => console.error(error))
	}

	getAllTeam() {
		axios
			.get("team/members", {
				params: { limit: 999, offset: 0 },
			})
			.then(
				action((success) => {
					const teams = success.data[0]
					this.listTeam = teams.map((team) => {
						return team
					})
				}),
			)
			.catch((error) => {
				console.error(error)
			})
	}
}

export default new TeamStore()
